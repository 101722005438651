import * as React from 'react';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {createTheme, styled} from '@mui/material/styles';
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useMediaQuery} from "./hooks";


const theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Poppins',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        }
    }
);

const WhiteBorderTextField = styled(TextField)(({ theme }) => ({
    ...theme.components.MuiTextField,
    'input': {
        color: "black",
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: 'black'
        }
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'black',
        },
        '&:hover fieldset': {
            borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.secondary,
        },
    },
    marginTop: theme.spacing(2),
    color:"white"
    // [theme.breakpoints.up('sm')]: {
    //     paddingTop: theme.spacing(6),
    //     paddingBottom: theme.spacing(6),
    // },
}));

export default function SubscribeFormBlack () {
    const [email, setEmail] = React.useState("")
    const [Linkedin, setLinkedin] = React.useState("")
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(false);
    const isXSPhone = useMediaQuery('(max-width: 290px)');

    const FORM_URL = `/api/subscribe`

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (email && !emailRegex.test(email)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
            let data = {email: "", Linkedin: ""}
            if (Linkedin !== "") {
                data = {...data, Linkedin}
            }
            if (email !== "") {
                data = {...data, email}
            }
            try {
                const response = await fetch(FORM_URL, {
                    method: "post",
                    body: JSON.stringify({
                        ...data
                    }),
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        accept: "application/json",
                    },
                })
                setSuccess(true)

            } catch (err) {
                console.log(err)
            }
        }
    }

    const handleEmailChange = (event) => {
        const { value } = event.target
        setEmail(value)
    }
    const handleLinkedinChange = (event) => {
        const { value } = event.target
        setLinkedin(value)
    }

    return (
        <Box style={{display:"flex", border:"1px solid black", borderRadius:"4px", padding:"10px 20px", width:"100%", margin:"0 auto", flexDirection:"column", justifyContent:"center"}}>
            <Typography variant="p" component="p" style={{textAlign:"left"}}>
                Subscribe to our email list to receive email updates on project's progress. Our LinkedIn space is currently under progress. Leave us your handle to get invited to the space.
            </Typography>
            <WhiteBorderTextField
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
                name="email_address"
                placeholder="Your email address"
                required
                sx={{minWidth: `${isXSPhone ? "155px" : "200px"}`}}
                value={email}
                onChange={handleEmailChange}
            />
            <WhiteBorderTextField
                id="outlined-basic"
                label="LinkedIn"
                variant="outlined"
                name="LinkedIn URL"
                placeholder="Your LinkedIn URL"
                sx={{minWidth: `${isXSPhone ? "155px" : "200px"}`}}
                value={Linkedin}
                onChange={handleLinkedinChange}
            />

            {!success && (
                <Button onClick={handleSubmit} variant="contained" sx={{maxWidth:"200px", display:"block", margin:"20px auto", backgroundColor:"#3b63f8", color:"white"}}>
                    SUBSCRIBE
                </Button>
            )}
            {success && (
                <Typography component="p" variant="p" sx={{color:"#1CAE1E", margin:"10px 0"}}>
                    THANK YOU! YOU ARE SUBSCRIBED
                </Typography>
            )}
            {error && email && (
                <Typography component="p" variant="p" style={{color: "red"}}>
                    {error}
                </Typography>
            )}
        </Box>
    )
}