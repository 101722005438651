import * as React from 'react';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {createTheme, styled} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {useMediaQuery} from "./hooks";

const theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Poppins',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        }
    }
);

const WhiteBorderTextField = styled(TextField)(({ theme }) => ({
    ...theme.components.MuiTextField,
    'input': {
        color: "white",
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: 'white'
        }
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.secondary,
        },
    },
    marginTop: theme.spacing(2),
    color:"white"
    // [theme.breakpoints.up('sm')]: {
    //     paddingTop: theme.spacing(6),
    //     paddingBottom: theme.spacing(6),
    // },
}));

export default function SubscribeForm () {
    const [email, setEmail] = React.useState("")
    const [Linkedin, setLinkedin] = React.useState("")
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(false);
    const isSmallPhone = useMediaQuery('(max-width: 400px)');

    const FORM_URL = `/api/subscribe`

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (email && !emailRegex.test(email)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
            let data = {email: "", Linkedin: ""}
            if (Linkedin !== "") {
                data = {...data, Linkedin}
            }
            if (email !== "") {
                data = {...data, email}
            }
            try {
                const response = await fetch(FORM_URL, {
                    method: "post",
                    body: JSON.stringify({
                        ...data
                    }),
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        accept: "application/json",
                    },
                })
                setSuccess(true)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const handleEmailChange = (event) => {
        const { value } = event.target
        setEmail(value)
    }
    const handleLinkedinChange = (event) => {
        const { value } = event.target
        setLinkedin(value)
    }

    return (
        <Box style={{display:"flex", border:"1px solid white", borderRadius:"4px", padding:"10px 20px", width:"100%", margin:"0 auto", flexDirection:"column", justifyContent:"center"}}>
            <Typography variant="h6" component="h6" sx={{color:"white", margin:"20px 0", fontWeight:"600", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                Receive project updates & Join the community page
            </Typography>
            <Typography variant="p" component="p" sx={{color:"white"}}>
                If you'd like to receive email updates on project's progress, subscribe to our email list. Our LinkedIn space is currently under progress. Leave us your handle and we will invite you to the space.
            </Typography>
            <WhiteBorderTextField
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
                name="email_address"
                placeholder="Your email address"
                required
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                sx={{minWidth: `${isSmallPhone ? "150px" : "250px"}`}}
                value={email}
                onChange={handleEmailChange}
            />
            <WhiteBorderTextField
                id="outlined-basic"
                label="LinkedIn"
                variant="outlined"
                name="LinkedIn URL"
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                placeholder="Your LinkedIn URL"
                sx={{minWidth: `${isSmallPhone ? "150px" : "250px"}`}}
                value={Linkedin}
                onChange={handleLinkedinChange}
            />
            {!success && (
                <Button onClick={handleSubmit} variant="contained" sx={{maxWidth:"200px", display:"block", margin:"20px auto", backgroundColor:"#3b63f8", color:"white"}}>
                    SUBSCRIBE
                </Button>
            )}
            {success && (
                <Typography component="p" variant="p" sx={{color:"#1CAE1E", margin:"10px 0"}}>
                    THANK YOU! YOU ARE SUBSCRIBED
                </Typography>
            )}
            {error && email && (
                <Typography component="p" variant="p" style={{color: "red"}}>
                    {error}
                </Typography>
            )}
        </Box>
    )
}