import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logoIMG from "../assets/logo192.png";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {createTheme, responsiveFontSizes, styled, ThemeProvider} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import mainVideo from "../assets/da000e02-f741-42b3-8ae5-a6bbf743c768.mp4";
import LOGOSimg from "../assets/logos-SA.png";
import Unisimg from "../assets/unis.png";
import FormElement from './Form';
import TextField from "@mui/material/TextField";
import {useNavigate, useLocation} from "react-router-dom";
import Link from "@mui/material/Link";
import {Container} from "@mui/material";
import {useMediaQuery} from "./hooks";

function Copyright() {
    return (
        <Typography variant="body2" style={{color:"white"}} align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://shapingautonomy.com">
                Shaping Autonomy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

let theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Poppins',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        }
    }
);

theme = responsiveFontSizes(theme)



const CustomTabs = styled(Tabs)(({ theme }) => ({
    ...theme.components.MuiTabs,
    // "& .MuiTabs-indicator": {
    // },

    "& .Mui-selected": {
        BackgroundColor:"red"

        // textDecoration: "underline"
    },
    color:"white",

}));

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
    {name:'Get involved', address:"/get-involved"},
    {name:'Mission', address:"#mission"},
    {name:'About', address:"#about"},
    {name:'Partners', address:"#partners"},
    {name:'Contact', address:"/contact"}
];



export default function BasicTabs(props: Props) {
    const [value, setValue] = React.useState(0);
    const [called, setCalled] = React.useState(false);
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [scrollTarget, setScrollTarget] = React.useState(undefined)
    const [scrolled, setScrolled] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)
    const history = useNavigate();
    const {state} = useLocation();
    const isMobile = useMediaQuery('(max-width: 800px)');
    const isWideScreen = useMediaQuery('(min-width: 4000px)');




    const trigger = useScrollTrigger({
        // target: scrollTarget,
        disableHysteresis: true,
        threshold: 100
    });

    const handleClickScroll = (address) => {
        if(address.startsWith("#")) {
            // const element = document.getElementById(address);
            // if (element) {
            //     // 👇 Will scroll smoothly to the top of the next section
            //     element.scrollIntoView({ behavior: 'smooth' });
            // }
            history("/")
            setTimeout(() => {
                const aboutPageNode = document.getElementById(address);
                aboutPageNode.scrollIntoView({behavior: "smooth"});
            }, 0);
        } else if(address.startsWith("getinv")) {
            const aboutPageNode = document.getElementById(address);
            aboutPageNode.scrollIntoView(true)
        } else {
            history(address)
        }

    };

    React.useEffect(() => {
        if(!called && !scrolled && state && 'goToTop' in state) {
            const PageNode = document.getElementById("startdif");
            PageNode.scrollIntoView({behavior: "smooth"});
            setScrolled(true)
        }
        if (!called && !scrolled && state && 'tabId' in state) {
            setValue(state.tabId)
            handleClickScroll("getinv")
            setScrolled(true)
        }
    });

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/*<Typography variant="h6" sx={{ my: 2 }}>*/}
            {/*    SHAPING AUTONOMY*/}
            {/*</Typography>*/}
            <img src={logoIMG} style={{maxWidth:"130px", margin:"15px 0 25px 0"}}/>
            <Divider sx={{ bgcolor: "white" }} />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={()=>{handleClickScroll(item.address)}}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCalled(true);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const FooterContainer = styled(Container)(({ theme }) => ({
        ...theme.components.MuiContainer,
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        zIndex: 5,
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    }));

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{background: trigger ? "black" : "none", boxShadow: !trigger ? "none" : "blue"}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton href={"/"} edge="start" color="inherit" aria-label="menu" sx={{marginRight: {sm: "auto"}, marginLeft: {xs: "auto" , sm: "0"}, display: 'flex'}}>
                        <img src={logoIMG}  onClick={()=>{}} style={{maxWidth:"100px", marginTop:"7px", flexGrow: 1, display: { xs: 'none', sm: 'block' }}}/>
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item.name} sx={{ color: '#fff' }} onClick={()=>{handleClickScroll(item.address)}}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "black", color: "white" },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <ThemeProvider theme={theme}>
                <Grid container id="startdif">
                    <Grid item xs={12} sx={{height: "100%", background:"black"}} ref={node => {
                        if (node) {
                            setScrollTarget(node);
                        }
                    }}>
                        <video muted playsInline autoPlay loop controls={false}  onLoadedData={() => setIsLoading(false)} style={{
                            opacity: !isLoading ? 0.4 : 0,
                            width:"100%", position:"fixed", top:"0", height: "100%", objectFit: "cover", zIndex: 0}}>
                            <source src={mainVideo} type="video/mp4"/>
                        </video>
                        <ThemeProvider theme={theme}>
                            <div style={{width: `${isMobile ? "85%" : isWideScreen ? "40%" : "70%"}`, position:"relative", margin:"150px auto 0 auto", textAlign:"center", zIndex: 5}}>
                                <Typography variant="h3" component="h3" sx={{color:"white", marginBottom:"20px", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                    Let's make a difference!
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>

                                <Box style={{display:"flex", height:"100%", borderRadius:"4px", border:"1px solid white", padding:"10px 20px", width:"100%", margin:"0 auto", flexDirection:"column", justifyContent:"center"}}>
                                    <Typography variant="h6" component="h6" sx={{fontWeight:"600", color:"white", marginBottom:"20px", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                        Current project status
                                    </Typography>
                                    <Typography variant="p" component="p" sx={{color:"white"}}>
                                        We are currently talking to domain experts from industry to gather insights on existing challenges. After this stage, we will begin sharing reports on the landscape and will be initiating collaborative spaces for research and engineering projects.
                                    </Typography>
                                </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                <FormElement  />
                                    </Grid>
                                </Grid>

                                <div style={{height:"35px", display:"hidden"}} id="getinv" />
                                <Typography variant="h4" noWrap component="h4" sx={{color:"white", margin:"20px 0"}}>
                                    Get Involved
                                </Typography>
                                <Typography variant="p" component="p" sx={{color:"white"}}>
                                    Choose a tab below to see how you can get involved.
                                </Typography>

                                <Box sx={{ width: '100%' }}>
                                    <Box>
                                        <CustomTabs  textColor="white"
                                               indicatorColor="primary"
                                               value={value}
                                               onChange={handleChange}
                                               aria-label="basic tabs example"
                                               centered
                                               TabIndicatorProps={{style: {color:'white'}}}
                                        >
                                            <Tab label="Industry" {...a11yProps(0)} />
                                            <Tab label="Academia" {...a11yProps(1)} />
                                            <Tab label="internal team" {...a11yProps(2)} />
                                        </CustomTabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0} style={{border: "0.5px solid white", marginTop:"5px"}}>
                                        <Typography variant="h5" noWrap component="h5" sx={{color:"white", margin:"20px 0"}}>
                                            Why participate?
                                        </Typography>
                                        <Typography variant="body1"  component="h6" sx={{color:"white", margin:"20px 0"}}>
                                            Have a say on the challenges from your perspective and contribute to defining obstacles that should be prioritized
                                        </Typography>
                                        <Typography variant="body1"  component="h6" sx={{color:"white", margin:"20px 0"}}>
                                            Tell us what you think should be the direction of projects that can address desired outcomes and help advance the field
                                        </Typography>
                                        <Typography variant="body1"  component="h6" sx={{color:"white", margin:"20px 0"}}>
                                            Participants are prioritized in defining and collaborating on projects, workshops and panels when our collaborative spaces is initiated
                                        </Typography>
                                        <Typography variant="body2"  component="body2" sx={{color:"white", margin:"20px 0"}}>
                                            For more details on benefits of project's outcome go to our benefits page.
                                        </Typography>
                                        <div style={{height:"0.5px", width:"100%", background:"white", margin:"20px 0"}}/>
                                        <Typography variant="h6" component="h6" sx={{color:"white", margin:"20px 0", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Who we want to talk to?
                                        </Typography>
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left", fontWeight: "600", margin:'10px 0'}}>
                                          Domain experts:
                                        </Typography>
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            Engineers OR researchers currently working or with experience working at companies active in any of the Autonomous Vehicles (AVs), Robotics, or Drones industries in sectors including but not limited to Aerospace, Agriculture, Warehouse & Logistics, Delivery, Inspection, Energy, Transportation, Defense, Maritime.
                                        </Typography>
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left", fontWeight: "600", margin:'10px 0'}}>
                                        With an emphasis on following roles:
                                        </Typography>
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            VPs, managers, or team leads leading teams working on autonomy and robotics components including but not limited to perception, navigation, planning, manipulation, V&V, and integration.
                                        </Typography>

                                        <Button variant="contained" sx={{margin:"30px auto", display:"block", backgroundColor:"#3b63f8", color:"white", maxWidth:"150px"}} target="_blank" href="https://calendly.com/shaping-autonomy/30min">
                                            Schedule a Talk
                                        </Button>
                                        <Typography variant="subtitle2" component="subtitle2" sx={{color:"white", margin:"20px 0", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            *please note that your scheduled session might get canceled if you are not a qualified participant
                                        </Typography>
                                        <div style={{height:"0.5px", width:"100%", background:"white", margin:"20px 0"}}/>
                                        <Typography variant="h6" component="h6" sx={{color:"white", margin:"20px 0", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Our goal in talking with you
                                        </Typography>
                                        <Typography variant="p"  component="p" sx={{color:"white", margin:"20px 0",textAlign:"left"}}>
                                            As part of project Shaping Autonomy's mission, we want to hear from you to understand your perspective on the existing challenges and hear your thoughts on making a greater impact through our efforts. These talks will help us inspire applied research and engineering projects to advance the field of autonomous systems.
                                        </Typography>
                                        <Typography variant="body2"  component="p" sx={{margin:"20px 0", textAlign:"left", color:"#3b63f8"}}>
                                            Note: Shaping Autonomy is a research project by nature and its intended goal is to advance the field of autonomous systems through collaboration between academia and industry. Accordingly, we do not collect any information for generating profit or targeted advertisement.
                                            <br/>
                                            <br/>

                                        </Typography>
                                        <Typography component="p" variant="p" sx={{color:"white", margin:"20px 0"}}>
                                            > Scroll to read more about the scheduled talks
                                        </Typography>
                                        <div style={{height:"0.5px", width:"100%", background:"white", margin:"20px 0"}}/>

                                        <Typography variant="h6" component="h6" sx={{color:"white", margin:"20px 0", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Who has participated so far?
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={12} md={6} style={{display:"flex",flexDirection:"column", justifyContent:"center"}}>
                                                <Typography variant="p"  component="p" sx={{ textAlign: "left", color:"white", margin:"20px 0"}}>
                                                    So far, we've talked to <span style={{fontSize:"1.5rem"}}> 60+ domain experts </span> (& counting!) across a wide range of sectors.
                                                </Typography>
                                                <Typography variant="p"  component="p" sx={{ textAlign: "left", color:"white", margin:"20px 0"}}>
                                                    Our participants have had experience in some of the most well-known companies
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <img src={LOGOSimg} style={{maxWidth: `${isMobile ? "100%" : "70%"}`}}/>
                                            </Grid>
                                        </Grid>
                                        <div style={{height:"0.5px", width:"100%", background:"white", margin:"20px 0"}}/>

                                        <Typography variant="h6" component="h6" sx={{color:"white", margin:"20px 0", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            What we discuss in the scheduled session?
                                        </Typography>

                                        <Typography variant="p" component="p" sx={{color:"white", margin:"20px 0", textAlign:"left"}}>
                                            <span style={{fontWeight:"600"}}>Format & Questions: </span> During the scheduled talk, we chat with you in an informal interview format.
                                            <br/>We ask high-level questions to direct the chat, including your perspective on the challenges within the industry and the obstacles that should be prioritized. Additionally, we ask for your thoughts on what projects should be initiated in academia to address desired outcomes through collaborative research and engineering.
                                            <br/><br/>** Please note that we don't ask for any proprietary information.
                                            <br/><br/><span style={{fontWeight:"600"}}>Timing: </span>Scheduled sessions typically last no more than 20 minutes.
                                            <br/><br/><span style={{fontWeight:"600"}}>Privacy & Data sharing: </span>Interviews are anonymous meaning that information about each participant's name, participation and other information that can be used to identify them is not collected nor shared. We only take notes in text format from the interviews and no other formats of data are collected.
                                            <br/>
                                            <br/> ** Please note all the insights from the participants are deemed as their personal opinion based on their experience and are not representative of their current or past companies.
                                        </Typography>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1} style={{border: "0.5px solid white", marginTop:"5px"}}>
                                        <Typography variant="h5" noWrap component="h5" sx={{color:"white", margin:"20px 0"}}>
                                            Why participate?
                                        </Typography>
                                        <Typography variant="body1"  component="h6" sx={{color:"white", margin:"20px 0"}}>
                                            Be a part of industry-inspired research and engineering projects with real-world applications and impact on the field
                                        </Typography>
                                        <Typography variant="body1"  component="h6" sx={{color:"white", margin:"20px 0"}}>
                                            Connect with new peers, and contribute to a new way of doing cross-institutional collaborative projects
                                        </Typography>
                                        <Typography variant="body2"  component="body2" sx={{color:"white", margin:"20px 0"}}>
                                            For more details on benefits of project's outcome go to our benefits page.
                                        </Typography>
                                        <div style={{height:"0.5px", width:"100%", background:"white", margin:"20px 0"}}/>
                                        <Typography variant="h6" noWrap component="h6" sx={{color:"white", margin:"20px 0"}}>
                                            Who should consider joining?
                                        </Typography>

                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            <span style={{fontWeight:"600"}}> Undergraduate, and graduate (Master, PhD) students </span> with relevant engineering OR research experience in the fields including but not limited to Machine learning, Robotics, Aerospace, Mechanical engineering, Electrical engineering, Design, Human-Computer Interaction, Human-Robot Interaction are welcome to join.
                                        </Typography>
                                        <br />
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            <span style={{fontWeight:"600"}}> Faculty members </span> currently working on relevant fields around autonomous systems, robotics and aerospace with interest in working on industry-inspired projects are more than welcome to join. Alternatively you can join to define projects that might solve identified challenges with applications in the industry and you can also request to mentor any of the projects that will be initiated.
                                        </Typography>
                                        <Button target="_blank" href="https://forms.gle/HzRnu8UKnkVcVdUj9" variant="contained" sx={{margin:"30px auto", display:"block", backgroundColor:"#3b63f8", color:"white", minWidth:"150px", maxWidth: "250px"}}>
                                            Complete participation form
                                        </Button>
                                        <Typography variant="p" component="p" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                            Please note unless otherwise explicitly specified, any contribution to Shaping Autonomy is deemed as volunteer work.
                                        </Typography>
                                        <br />
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            <span style={{fontWeight:"600"}}> Involvement and time commitment </span> for the projects are in your control, you can be as involved as you want.
                                            Projects' timeline will be dependent on the teams that will be assigned to projects and project's scope and proposal.
                                            After projects are initiated, others can send request to join projects and project teams will be formed by reviewing potential members interested in joining the project based on their preferred time commitment and match to the project. All team members will be confirmed with project's lead who will be in charge of defining the proposal.
                                            More information about projects will be coming soon.
                                            Prior to assignment to any project, all information regarding time commitment and potential results of the project will be communicated and confirmed with all members.

                                        </Typography>

                                        <div style={{height:"0.5px", width:"100%", background:"white", margin:"20px 0"}}/>

                                        <Typography variant="h6" noWrap component="h6" sx={{color:"white", margin:"20px 0"}}>
                                            Who has participated so far?
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={12} md={6} style={{display:"flex",flexDirection:"column", justifyContent:"center"}}>
                                                <Typography variant="p"  component="p" sx={{ textAlign: "left", color:"white", margin:"20px 0"}}>
                                                    So far, we've received <span style={{fontSize:"1.5rem"}}> 85+ (& counting!) student and faculty member interest </span> across a wide range of engineering and research fields from top-ranked universities.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <img src={Unisimg} style={{maxWidth: `${isMobile ? "100%" : "70%"}`}}/>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2} style={{border: "0.5px solid white", marginTop:"5px"}}>
                                        <Typography variant="h5" component="h5" sx={{color:"white", margin:"20px 0", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Considering joining the internal team?
                                        </Typography>
                                        <Typography variant="p" component="p" sx={{color:"white", margin:"20px 0", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Contact us directly at research@shapingautonomy.com OR complete the application form by clicking on the button below
                                        </Typography>
                                        <Typography variant="h6"  component="p"
                                                    sx={{color:"white" , textAlign: "left", fontWeight: "600", margin:'10px 0'}}>
                                            Advisory
                                        </Typography>
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            We are looking for experts from both industry and academia with at least 8 years of experience in the field of autonomous system to join this project's advisory team.
                                            As part of the advisory team, you can attend leadership meetings, contribute to directing the project, and help us maximize the impact in addressing desired outcomes for the field.
                                        </Typography>
                                        <Typography variant="h6"  component="p"
                                                    sx={{color:"white" , textAlign: "left", fontWeight: "600", margin:'10px 0'}}>
                                            Mentorship
                                        </Typography>
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            We are building an internal mentorship team for Shaping Autonomy. Mentors will be guiding the projects that will be initiated in collaborative spaces and will be meeting with project teams to guide them towards project objectives and to make sure it's aligned with project proposals.
                                            Mentors from academia should be senior researchers (faculty, PhD, PostDoc) or advanced master of engineering students with at least 4 years of experience in fields related to autonomous systems.
                                            Mentors from industry should be team lead, senior researchers or senior engineers with at least 5 years of industry experience in fields related to autonomous systems.
                                        </Typography>
                                        <Typography variant="h6"  component="p"
                                                    sx={{color:"white" , textAlign: "left", fontWeight: "600", margin:'10px 0'}}>
                                            Internal Research & Engineering team
                                        </Typography>
                                        <Typography variant="p"  component="p"
                                                    sx={{color:"white" , textAlign: "left"}}>
                                            If you are a student, a researcher or engineer studying / working in fields relevant to autonomous systems with any seniority level and you want to become part of the internal team for Shaping Autonomy, send us your CV and let us know.
                                            Internal research and engineering members will be constantly involved in all stages of project's progress, will receive recognition for their contributions, and will get a chance to work with senior members of the field, do project management for collaborative spaces, and make new connections.
                                        </Typography>

                                        <Button variant="contained" href="https://forms.gle/K5DqG1MZG3XyC6fGA" target="_blank" sx={{margin:"30px auto", display:"block", backgroundColor:"#3b63f8", color:"white", maxWidth:"250px"}}>
                                            Complete application form
                                        </Button>
                                    </CustomTabPanel>
                                </Box>
                            </div>

                        </ThemeProvider>
                    </Grid>
                    <FooterContainer component="footer" sx={{background:"black", maxWidth:"100vw !important", margin:"0"}}>
                        <div style={{display:"flex", justifyContent:"center", color:"white"}}>
                            <Link style={{textDecoration:"none", marginRight:"10px"}} color="inherit" href="/privacy">
                                Privacy Policy
                            </Link>
                            .
                            <Link style={{textDecoration:"none", marginLeft:"10px"}} color="inherit" href="/terms-of-use">
                                {"   "} Terms of Use
                            </Link>
                        </div>
                        <Box mt={5}>
                            <Copyright />
                        </Box>

                    </FooterContainer>
                </Grid>

            </ThemeProvider>
        </Box>
    );
}