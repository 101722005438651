import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import P3Logo from '../assets/p3.jpg';
import Headshotp from '../assets/OFFICIAL.png';
import WandDLogo from '../assets/WomenAndDrones-logo-websize.webp';
import AuvsiLogo from '../assets/AUVSI-Logo New 2022.webp';
import NSFlogo from '../assets/NSF_Official_lodgo_High_Res_1200ppi.png';
import mainVideo from '../assets/mainvideo.mp4';
import {useMediaQuery} from './hooks';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import mainShapeVideo from '../assets/mainvideoo.mp4';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import SearchIcon from '../assets/5909280.png';
import WorkIcon from '../assets/1900496.png';
import logoIMG from '../assets/logo192.png';
import Mainback from '../assets/mainback2.png';
import Circleback from '../assets/circle.png';
import Netimg from '../assets/network.png';
import { useNavigate } from "react-router-dom";
import SpotlightIcon from '../assets/387-3877618_the-icon-is-a-picture-of-a-spotlight.png'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import {Container} from "@mui/material";
import FormElement from "./FormBlack";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://shapingautonomy.com">
                Shaping Autonomy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


let theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Poppins',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        }
    }
);

theme = responsiveFontSizes(theme)


theme.typography.h1 = {
    fontSize: '2.6rem',
    '@media (max-width:500px)': {
        fontSize: '2.4rem',
    },
    '@media (max-width:376px)': {
        fontSize: '2.2rem',
    },
    '@media (min-width:600px)': {
        fontSize: '3.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '4.7rem',
    },
    "@media (min-width:3000px)": {
        fontSize: '6.7rem',
    },
};


interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
    {name:'Get involved', address:"/get-involved"},
    {name:'Mission', address:"#mission"},
    {name:'About', address:"#about"},
    {name:'Partners', address:"#partners"},
    {name:'Contact', address:"/contact"}
    ];


export default function Home(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [Linkedin, setLinkedin] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(true)
    const [isLoadingsecond, setIsLoadingSecond] = React.useState(true)
    const history = useNavigate();
    const isMobile = useMediaQuery('(max-width: 800px)');
    const isIpad = useMediaQuery('(min-width: 820px) and (max-width: 915px)');
    const isIpadLand = useMediaQuery('(min-width: 820px) and (max-width: 1024px)');
    const isMac13 = useMediaQuery('(min-width: 1200px) and (max-width: 1550px)');
    const isSmallPhone = useMediaQuery('(max-width: 400px)');
    const isXSmallPhone = useMediaQuery('(max-width: 322px)');
    const isWideScreen = useMediaQuery('(min-width: 4000px)');

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLinkedinChange = (event) => {
        const { value } = event.target
        setLinkedin(value)
    }

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });
    const handleClickScroll = (address) => {
        if(address.startsWith("#")) {
            const element = document.getElementById(address);

            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                if (isMobile) {
                    element.scrollIntoView(true)
                } else {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } else if (address.startsWith("/get")) {
            history('/get-involved', { state: { goToTop: true } })
        } else {
            history(address)
        }

    };

    const handleLinkedinSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        let data = {email: "", Linkedin: ""}
        if (Linkedin !== "") {
            data = {...data, Linkedin}
        }

        try {
            const response = await fetch("/api/subscribe", {
                method: "post",
                body: JSON.stringify({
                    ...data
                }),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    accept: "application/json",
                },
            })
            handleClose()
        } catch (err) {
            console.log(err)
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/*<Typography variant="h6" sx={{ my: 2 }}>*/}
            {/*    SHAPING AUTONOMY*/}
            {/*</Typography>*/}
            <img src={logoIMG} style={{maxWidth:"130px", margin:"15px 0 25px 0"}}/>
            <Divider sx={{ bgcolor: "white" }} />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} component={Button} onClick={()=>{handleClickScroll(item.address)}}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "white",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        boxShadow: "none",
        // textAlign: 'center',
        color: "white",
        maxWidth: "60%",
        textAlign: "left",
            [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
        },
    }));

    const FooterContainer = styled(Container)(({ theme }) => ({
        ...theme.components.MuiContainer,
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    }));



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{background: trigger ? "black" : "none", boxShadow: !trigger ? "none" : "blue"}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton href={"/"} edge="start" color="inherit" aria-label="menu" sx={{marginRight: {sm: "auto"}, marginLeft: {xs: "auto" , sm: "0"}, display: 'flex'}}>
                        <img src={logoIMG} style={{maxWidth:"100px", marginTop:"7px", flexGrow: 1, display: { xs: 'none', sm: 'block' }}}/>
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item.name} sx={{ color: '#fff' }} onClick={()=>{handleClickScroll(item.address)}}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "black", color: "white"},
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{background:"black", width:"100%"}}>
                {/*<Toolbar />*/}
                <Grid container>
                    <Grid item xs={12} sx={{height: "100vh", background:"black"}}>
                        <div style={{position: "relative",
                            overflow: "hidden",
                            width: "100%",
                            height: "100%"}}>
                            <img src={Mainback} style={{
                                position: "absolute",
                                opacity: isLoading ? 0.6 : 0,
                                width: `${isMobile ? "100vw" : "100%"}`, top:"0", left:"0", height: "100%", objectFit: "cover", zIndex: 0,
                            }}  />
                            <video muted playsInline autoPlay loop onLoadedData={() => setIsLoading(false)} style={{
                                position: "absolute",
                                opacity: !isLoading ? 0.6 : 0,
                                width: `${isMobile ? "100vw" : "100%"}`, top:"0", left:"0", height: "100%", objectFit: "cover", zIndex: 0,
                            }}  >
                                <source src={mainShapeVideo} type="video/mp4"/>
                            </video>
                        </div>
                        <ThemeProvider theme={theme}>
                            <div style={{position:"absolute", top:"50%", maxWidth: "100%", left: `${isMobile ? "0" : "50%"}`, transform: `${isMobile ? "translate(0%, -50%)" : "translate(-50%, -50%)"}`, textAlign:"center"}}>
                                <Typography variant="h1" component="h1" sx={{color:"white", whiteSpace: {xs: "normal", xl:"nowrap"}}}>
                                    Shaping Autonomy
                                </Typography>
                                <Typography variant="h4" sx={{whiteSpace: {md: "normal", xl:"nowrap", color:"white"}}} component="h4">
                                    Bringing the builders together to shape the future of autonomy
                                </Typography>
                                <Typography variant="h6" component="h6" sx={{color:"white", marginTop: "20px", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                    The biggest project with the mission to bridge the gap between industry and academia to help solve the technical challenges collectively
                                </Typography>
                            </div>

                        </ThemeProvider>
                    </Grid>
                    <Grid id="#mission" item xs={12} sx={{position: "relative", height: `${isXSmallPhone ? "180vh" : isSmallPhone ? "135vh" : "100vh"}`, background:"black", boxShadow:"0px 45px 25px 0px #72727221", zIndex:"4"}}>

                        <div style={{position: "relative",
                            overflow: "hidden",
                            width: "100%",
                            height: "100%"}}>
                            <img src={Circleback} style={{
                                position: "absolute",
                                opacity: isLoadingsecond ? 1 : 0,
                                width: `${isMobile ? "100vw" : "100%"}`, top:"0", left:"0", height: "100%", objectFit: "cover",
                            }}  />

                            <video muted playsInline autoPlay loop onLoadedData={() => setIsLoadingSecond(false)} style={{
                                position: "absolute",
                                opacity: !isLoadingsecond ? 1 : 0,
                                width: `${isMobile ? "100vw" : "100%"}`, top:"0", left:"0", height: "100%", objectFit: "cover"}}>
                                <source src={mainVideo} type="video/mp4"/>
                            </video>
                        </div>
                        <ThemeProvider theme={theme}>
                            <Typography variant="p"  component="p"
                                        sx={{
                                position:"absolute", top:"50%", left: `${isMobile ? "0" : isMac13 ? "30%" : "50%"}`, transform: `${isMobile ? "translate(0%, -50%)" : isMac13 ? "translate(-20%, -50%)" : "translate(-50%, -50%)"}`, color:"white", padding: "20px",
                                background: "rgb(1 1 1 / 80%)",
                                boxShadow: "0px 0px 80px 50px #000000"}}>
                                Both fields of Robotics and AI have come so far in recent years with impressive achievements in the applications of AI in the real world. Autonomy, on the other hand, is an emerging area that has remained at a research stage across separate fields.
                                Applying autonomy in real-world applications and advancing autonomy in robot vehicle systems is hard. Most autonomous system vehicles in the real world are still under supervised control by a human and deployed in limited operation environments due to many challenges that exist for advancing autonomy.
                                Reliable autonomy can unlock many opportunities across different sectors and it can keep humans safe and help them achieve goals more efficiently. <br/>
                                <br/>
                                <span style={{fontWeight: 600}}> Our mission in this project is to accelerate collective progress towards advanced reliable autonomy by identifying challenges and risks that currently exist, envisioning the future that can be unlocked by solving them across different sectors, and empowering the autonomous systems research and engineering community from industry and academia to work on solutions that goes beyond a single lab or a single team. </span>
                            </Typography>
                        </ThemeProvider>
                    </Grid>
                    <Grid id="#about" item xs={12} sx={{position: "relative", height: "100%", background:"white", display:"flex", flexDirection:"column"}}>
                        <ThemeProvider theme={theme}>
                            <Typography variant="h3"  component="h3"
                                        sx={{
                                            color:"black", padding: "30px", fontWeight: 600,
                                            marginTop:"30px",
                                            borderBottom: "1px solid gray"
                                        }}

                            >
                                PROJECT PLAN
                            </Typography>
                            <Typography variant="h4"  component="h4"
                                        sx={{
                                            color:"black", padding: "30px",
                                            marginBottom: "30px"
                                        }}>
                                EXPECTED OUTCOMES
                            </Typography>
                            <Grid container spacing={1} sx={{width:"100vw"}}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Card sx={{ maxWidth: `${isMobile ? "100%" : "80%"}`, margin:"auto", boxShadow:"none"}}>
                                        {/*<CardActionArea>*/}
                                            <CardMedia
                                                component="img"
                                                height="100%"
                                                image={SearchIcon}
                                                alt="green iguana"
                                            />
                                            <CardContent>
                                                <Typography sx={{color:"#3b63f8"}} gutterBottom variant="h5" component="div">
                                                    Live reports, papers, & toolkits
                                                </Typography>
                                                <Typography sx={{color:"black"}} variant="body1" color="text.secondary">
                                                    Identifying challenges in collaboration with industry experts across sectors, finding patterns and open-sourcing main issues through interactive reports, creating frameworks and toolkits that will map challenges to candidate solutions and enabling cross-institutional collaborations for paper publications.
                                                </Typography>
                                            </CardContent>
                                        {/*</CardActionArea>*/}
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ maxWidth: `${isMobile ? "100%" : "80%"}`, margin:"auto", boxShadow:"none" }}>
                                        {/*<CardActionArea>*/}
                                            <CardMedia
                                                component="img"
                                                height="100%"
                                                image={WorkIcon}
                                                alt="green iguana"
                                            />
                                            <CardContent>
                                                <Typography sx={{color:"#3b63f8"}} gutterBottom variant="h5" component="div">
                                                    Collaborative Spaces
                                                </Typography>
                                                <Typography sx={{color:"black"}} variant="body1" color="text.secondary">
                                                    New generation of cross-institutional collaborations for research and technology development. Collaborative spaces will be formed in three formats: projects, panels, working groups.
                                                    For more information on each space see below.
                                                </Typography>
                                            </CardContent>
                                        {/*</CardActionArea>*/}
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ maxWidth: `${isMobile ? "100%" : "80%"}`, margin:"auto", boxShadow:"none" }}>
                                        {/*<CardActionArea>*/}
                                            <CardMedia
                                                component="img"
                                                height="100%"
                                                image={SpotlightIcon}
                                                alt="green iguana"
                                            />
                                            <CardContent>
                                                <Typography sx={{color:"#3b63f8"}} gutterBottom variant="h5" component="div">
                                                    Spotlight
                                                </Typography>
                                                <Typography sx={{color:"black"}} variant="body1" color="text.secondary">
                                                    Whether it's a technical topic that needs attention, an engineer or researcher who deserves recognition or a company's technical excellence, it will be under this project's spotlight.
                                                </Typography>
                                            </CardContent>
                                        {/*</CardActionArea>*/}
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{width: {lg: "80vw"}, margin: {lg: "45px auto"}}}>
                                <Grid item xs={12} sm={12} sx={{textAlign: "left", borderBottom: "1px solid black", margin:{xs: "40px 0"}}}>
                                    <Typography sx={{color:"black"}} gutterBottom variant="h5" component="h5">
                                       > Collaborative Spaces <span style={{color:"grey"}}> : breakdown </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ maxWidth: `${isMobile ? "100%" : "90%"}`, margin:"auto", boxShadow:"none" }}>
                                        {/*<CardActionArea>*/}
                                            {/*<CardMedia*/}
                                            {/*    component="img"*/}
                                            {/*    height="140"*/}
                                            {/*    image={WorkIcon}*/}
                                            {/*    sx={{background:"black"}}*/}
                                            {/*    alt="green iguana"*/}
                                            {/*/>*/}
                                            <CardContent>
                                                <Typography sx={{color:"#3b63f8"}} gutterBottom variant="h5" component="div">
                                                    Project space
                                                </Typography>
                                                <Typography sx={{color:"black"}} variant="body2" color="text.secondary">
                                                    Project space is where companies can define a project related to one of the challenges and share timeline, objectives, and expected outcomes.
                                                    This will activate a research and development or engineering challenge for the students to work on the project.
                                                    The project spaces can also be used by students to propose a project for a solution idea relevant to one of the challenges.
                                                    All projects will be assigned mentors who will work with the students for putting together a team to work on the project.


                                                </Typography>
                                            </CardContent>
                                        {/*</CardActionArea>*/}
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ maxWidth: `${isMobile ? "100%" : "90%"}`, margin:"auto", boxShadow:"none" }}>
                                        {/*<CardActionArea>*/}
                                            {/*<CardMedia*/}
                                            {/*    component="img"*/}
                                            {/*    height="140"*/}
                                            {/*    image={SpotlightIcon}*/}
                                            {/*    alt="green iguana"*/}
                                            {/*/>*/}
                                            <CardContent>
                                                <Typography sx={{color:"#3b63f8"}} gutterBottom variant="h5" component="div">
                                                    Working group space
                                                </Typography>
                                                <Typography sx={{color:"black"}} variant="body2" color="text.secondary">
                                                    The working group space will enable companies and organizations to bring their existing working group to the attention of the technical community from industry and academia. The space enables working groups to communicate technical gaps to a broader community and bring new insights for potential solutions. <br /> Both organizations and student groups can also propose a new working group with a topic in mind for inspiring research and technical development relevant to a challenge within the field.
                                                </Typography>
                                            </CardContent>
                                        {/*</CardActionArea>*/}
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ maxWidth: `${isMobile ? "100%" : isIpad ? "90%" : "80%"}`, margin:"auto", boxShadow:"none" }}>
                                        {/*<CardActionArea>*/}
                                            {/*<CardMedia*/}
                                            {/*    component="img"*/}
                                            {/*    height="140"*/}
                                            {/*    image={SpotlightIcon}*/}
                                            {/*    alt="green iguana"*/}
                                            {/*/>*/}
                                            <CardContent id="#approach">
                                                <Typography sx={{color:"#3b63f8"}} gutterBottom variant="h5" component="div">
                                                    Panel space
                                                </Typography>
                                                <Typography sx={{color:"black"}} variant="body2" color="text.secondary">
                                                    The panel space will be dedicated to virtual and in-person panels and workshops with domain experts from industry and academia to discuss a relevant technical topic or urgent challenge and to brainstorming possible solutions.

                                                </Typography>
                                            </CardContent>
                                        {/*</CardActionArea>*/}
                                    </Card>
                                </Grid>
                            </Grid>

                            <Typography  variant="h4"  component="h4"
                                        sx={{
                                            color:"black", padding: "30px",
                                            borderTop: "1px solid gray"
                                        }}>
                               APPROACH
                            </Typography>
                            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
                                <Item
                                    sx={{
                                        my: 1,
                                        mx: 'auto',
                                        p: 2,
                                    }}
                                >
                                    <Stack spacing={2} direction="row" alignItems="center" >
                                        <Avatar sx={{background:"black",  fontSize:`${isMobile ? "1.5rem" : "2rem"}`, outline:"1px solid white", padding: `${isMobile ? "25px" : "30px"}`}}>1</Avatar>
                                        <Typography sx={{color:"black", whiteSpace: {md: "normal", xl:"nowrap"}}} variant="h6">
                                            Talk with industry experts to gather insights on existing and future challenges
                                        </Typography>
                                    </Stack>
                                </Item>
                                <Item
                                    sx={{
                                        my: 1,
                                        mx: 'auto',
                                        p: 2,
                                    }}
                                >
                                    <Stack spacing={2} direction="row" alignItems="center">
                                        <Avatar sx={{background:"black",  fontSize:`${isMobile ? "1.5rem" : "2rem"}`, outline:"1px solid white", padding: `${isMobile ? "25px" : "30px"}`}}>2</Avatar>
                                        <Typography variant="h6"  sx={{color:"black"}}>
                                            Create cross-sector taxonomy of autonomy challenges + framework of gaps and required capability areas informed by current and future applications
                                        </Typography>
                                    </Stack>
                                </Item>
                                <Item
                                    sx={{
                                        my: 1,
                                        mx: 'auto',
                                        p: 2,
                                    }}
                                >
                                    <Stack spacing={2} direction="row" alignItems="center">
                                        <Avatar sx={{background:"black",  fontSize:`${isMobile ? "1.5rem" : "2rem"}`, outline:"1px solid white", padding: `${isMobile ? "25px" : "30px"}`}}>3</Avatar>
                                        <Typography variant="h6" sx={{color:"black", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Enable collaborative research and engineering projects for addressing identified challenges
                                        </Typography>
                                    </Stack>
                                </Item>
                                <Item
                                    sx={{
                                        my: 1,
                                        mx: 'auto',
                                        p: 2,
                                    }}
                                >
                                    <Stack spacing={2} direction="row" alignItems="center">
                                        <Avatar sx={{background:"black",  fontSize:`${isMobile ? "1.5rem" : "2rem"}`, outline:"1px solid white", padding: `${isMobile ? "25px" : "30px"}`}}>4</Avatar>
                                        <Typography variant="h6" sx={{color:"black", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Form impactful working groups and panels to foster ongoing technical community collaboration
                                        </Typography>
                                    </Stack>
                                </Item>
                                <Item
                                    sx={{
                                        my: 1,
                                        mx: 'auto',
                                        p: 2,
                                    }}
                                >
                                    <Stack spacing={2} direction="row" alignItems="center">
                                        <Avatar sx={{background:"black",  fontSize:`${isMobile ? "1.5rem" : "2rem"}`, outline:"1px solid white", padding: `${isMobile ? "25px" : "30px"}`}}>5</Avatar>
                                        <Typography variant="h6" sx={{color:"black", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Evaluate the impact of ongoing projects, recognize and scale the successful projects in partnership with industry
                                        </Typography>
                                    </Stack>
                                </Item>

                                <Item
                                    sx={{
                                        my: 1,
                                        mx: 'auto',
                                        p: 2,
                                    }}
                                >
                                    <Stack spacing={2} direction="row" alignItems="center" style={{marginBottom:"30px"}}>
                                        <Avatar sx={{background:"black",  fontSize:`${isMobile ? "1.5rem" : "2rem"}`, outline:"1px solid white", padding: `${isMobile ? "25px" : "30px"}`}}>6</Avatar>
                                        <Typography variant="h6" sx={{color:"black", whiteSpace: {md: "normal", xl:"nowrap"}}}>
                                            Identify areas where additional resources and investments are needed to raise awareness
                                        </Typography>
                                    </Stack>
                                </Item>

                            </Box>
                            <Typography variant="h4"  component="h4"
                                        sx={{
                                            color:"black", padding: "30px",
                                            borderTop: "1px solid gray"
                                            }}>
                                <span style={{color:"#3b63f8"}}> BENEFITS </span> > INDUSTRY
                            </Typography>
                            <Box sx={{px: {xs: 4, md: 30}, margin: "30px 0"}}>
                                <Typography variant="body1" sx={{color:"black"}}>
                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}>Contribute</span> to shaping a safe and sustainable future through autonomy
                                    that is only enabled by collaboration in achieving desired outcomes across the field of autonomous systems



                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Accelerate </span> exponential growth by joining forces with exceptional engineers and researchers across academia and industry

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Inspire </span> applied research and engineering projects with impact on your industry that can benefit the field through collaboration on solving identified challenges

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Raise awareness </span> of challenges that can help you and the industry to move forward

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Leverage </span> from large-scale research, engineering and innovation that will be enabled through this project

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Celebrate </span> your technical achievements with the community and connect with talented engineers and researchers

                                </Typography>
                                <Button variant="contained" onClick={()=>{history('/get-involved', { state: { tabId: 0 } })}} sx={{margin:"30px auto", display:"block", backgroundColor:"#3b63f8", minWidth:"150px"}}>
                                    Participate
                                </Button>
                            </Box>
                            <Typography variant="h4"  component="h4"
                                        sx={{
                                            color:"black", padding: "30px",
                                            borderTop: "1px solid gray"
                                        }}>
                                <span style={{color:"#3b63f8"}}> BENEFITS </span> > ACADEMIA
                            </Typography>
                            <Box sx={{px: {xs: 4, md: 30}, margin: "30px 0 0 0"}}>
                                <Typography variant="body1" sx={{color:"black"}}>
                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}>Contribute</span> to shaping a safe and sustainable future through autonomy
                                    that is only enabled by collaboration in achieving desired outcomes across the field of autonomous systems



                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Change </span> the way academic projects are done and help enable a collaborative future for the field that goes beyond the lab

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Increase </span> the impact of your projects by working on research and engineering challenges that can shape the future of autonomous systems in real-world

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Communicate </span> your contributions in ways that matters to the future of the field and the industry

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Be a part of </span> large-scale research, engineering and innovation that will be enabled through this project

                                    <span style={{fontWeight:600, fontSize:`${isMobile? "1.7rem" : "2rem"}`}}> Get recognized </span> for your research and engineering achievements within the community

                                </Typography>
                                <Button onClick={()=>{history('/get-involved', { state: { tabId: 1 } })}} variant="contained" sx={{margin:"30px auto 8vh auto", display:"block", backgroundColor:"#3b63f8", minWidth:"150px"}}>
                                    Join
                                </Button>
                            </Box>
                            <Typography variant="h4"  component="h4"
                                        sx={{
                                            color:"black", padding: "30px",
                                            borderTop: "1px solid gray"
                                        }}>
                                {/*<span style={{color:"#3b63f8"}}> Team </span> > INDUSTRY*/}
                            </Typography>
                            <Grid container spacing={1} sx={{width:"100vw", zIndex:"5", boxShadow:"0px 75px 65px -67px #4e4e4e"}}>
                                <Grid item xs={12} style={{margin:"30px auto 50px auto", display:"flex", flexDirection:`${isMobile ? "column" : "row"}`, minHeight: "200px", padding: "20px 5%", maxWidth: `${isMobile ? "100%" : "70%"}`}}>
                                    <div style={{flexGrow: "1", marginRight:"0px", margin: `${isMobile ? "auto" : ""}`}}>
                                        <img src={Headshotp} style={{maxWidth:"130px"}}/>
                                    </div>
                                    <div style={{flexGrow: "3"}}>
                                        <Typography sx={{color:"black"}} variant="body1" color="text.secondary">
                                            <blockquote>
                                            I believe this is a time when we can make significant progress by bringing both industry and academia together to connect and collaborate on transformative projects that have real-world impact.
                                            By enabling collaboration across disciplines and entities in this project, we can collectively contribute to the outcomes that will shape the future of autonomy for the benefit of humanity.
                                            </blockquote>
                                        </Typography>
                                        <Typography style={{color:"black", marginTop:"15px", marginLeft: `${isMobile? "30px" : "0"}`}} variant="body2" color="text.secondary">
                                            <span style={{fontSize:"16px"}}>— Mehrnaz Sabet, </span> Project Director  <br />
                                            <span style={{fontSize:"12px"}}>PhD Candidate, Cornell University</span>
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} id="#partners">
                                    <Typography variant="h4"  component="h4"
                                                sx={{
                                                    color:"black", padding: "30px",
                                                    borderTop: "1px solid gray"
                                                }}>
                                        <span style={{color:"#3b63f8"}}> PARTNERS & SPONSERS </span>
                                    </Typography>
                                    <Grid item xs={12} sx={{display:"flex", justifyContent: "center", flexDirection: `${isMobile ? "column": "row"}`, alignItems: "center"}}>
                                            <img src={AuvsiLogo} style={{flexGrow: 1, maxWidth:"200px", height:"auto", margin:"auto 15px"}} />
                                            <img src={WandDLogo} style={{flexGrow: 1, maxWidth:"200px", height:"auto"}} />
                                            <img src={P3Logo} style={{flexGrow: 1, maxWidth:"200px", height:"auto", margin:"auto 15px"}} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>

                                    <Grid item xs={12} sx={{display:"flex", justifyContent: "center"}}>
                                        <Typography sx={{ color:"#535252", margin:"auto 20px"}} gutterBottom variant="body1" component="div">
                                            Part of this project's initiative is funded by
                                        </Typography>
                                        <img src={NSFlogo} style={{flexGrow: 1, maxWidth:"130px", height:"auto", margin:"auto 15px"}} />
                                    </Grid>
                                </Grid>
                                <Button variant="contained" target="_blank" href="mailto: partnership@shapingautonomy.com" sx={{margin:"30px auto 8vh auto", display:"block", backgroundColor:"#3b63f8", minWidth:"150px"}}>
                                    Contact us for partnership
                                </Button>
                            </Grid>
                            <Grid container style={{background: `url(${Netimg})`, backgroundSize:"cover", backgroundPosition:"left", overflow: `${isIpad ? "scroll" : ""}`}}>

                                <Grid xs={12} style={{margin:"auto",padding:"50px 0 0 0", display:"flex", flexDirection:`${(isMobile || isIpad) ? "column" : "row"}`, textAlign:"center", maxWidth:`${isXSmallPhone ? "100%" : isIpadLand ? "90%" : "75%"}`}}>
                                    <Grid xs={12} md={2} sx={{minHeight: "200px"}}>
                                        <Typography variant="h6" style={{textAlign:"left", fontWeight: 600}}>
                                            Our Work
                                        </Typography>
                                        <Divider />
                                        <nav aria-label="secondary mailbox folders">
                                            <List>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={()=>{handleClickScroll("#mission")}}>
                                                        <ListItemText primary="Mission" />
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton component="a" onClick={()=>{handleClickScroll("#about")}}>
                                                        <ListItemText primary="Impact" />
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton component="a" onClick={()=>{handleClickScroll("#approach")}}>
                                                        <ListItemText primary="Approach" />
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </nav>
                                    </Grid>
                                    <Grid xs={12} md={2} sx={{margin:`${isMobile ? "0" : "0 15px"}`, minHeight: "200px"}}>
                                        <Typography variant="h6" style={{textAlign:"left", fontWeight: 600, whiteSpace: "nowrap"}}>
                                            Get Involved
                                        </Typography>
                                        <Divider />
                                        <nav aria-label="secondary mailbox folders">
                                            <List>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={()=>{history('/get-involved', { state: { tabId: 0 } })}}>
                                                        <ListItemText primary="Industry" />
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton component="a" onClick={()=>{history('/get-involved', { state: { tabId: 1 } })}}>
                                                        <ListItemText primary="Academia" />
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton component="a" onClick={()=>{history('/get-involved', { state: { tabId: 2 } })}}>
                                                        <ListItemText primary="Internal Team" />
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </nav>
                                    </Grid>
                                    <Grid xs={12} md={4} sx={{margin:`${isMobile ? "0" : "0 15px"}`, minHeight: "200px"}}>
                                        <Typography variant="h6" style={{textAlign:"left", fontWeight: 600}}>
                                            Contact
                                        </Typography>
                                        <Divider />
                                        <nav aria-label="secondary mailbox folders">
                                            <List>
                                                <ListItem disablePadding>
                                                    <ListItemButton
                                                        component="a"
                                                        href="mailto: info@shapingautonomy.com"
                                                        target="_blank"
                                                        style={{paddingLeft:"0px"}}
                                                    >
                                                        <ListItemText primary="info@shapingautonomy.com" />
                                                    </ListItemButton>
                                                </ListItem>
                                                {!isSmallPhone && <ListItem disablePadding>
                                                    <ListItemButton component="a"
                                                                    href="mailto: info@shapingautonomy.com"
                                                                    target="_blank"
                                                                    style={{paddingLeft:"0px"}}
                                                    >
                                                        <ListItemText primary="partnership@shapingautonomy.com"/>
                                                    </ListItemButton>
                                                </ListItem>
                                                }
                                                <ListItem disablePadding>
                                                    <ListItemButton component="a"
                                                                    href="mailto: info@shapingautonomy.com"
                                                                    target="_blank"
                                                                    style={{paddingLeft:"0px"}}
                                                    >
                                                        <ListItemText primary="media@shapingautonomy.com" />
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </nav>
                                    </Grid>
                                    <Grid xs={12} md={6} sx={{minHeight: "200px"}}>
                                        <FormElement />
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sx={{textAlign:"center"}}>

                                        <IconButton
                                            color="inherit"
                                            size="large"
                                            onClick={handleClickOpen}
                                            // sx={{ mr: 2, display: { sm: 'none' } }}
                                        >
                                            <LinkedinIcon fontSize="large" />
                                        </IconButton>
                                        <IconButton
                                            color="inherit"
                                            size="large"
                                            href="mailto: info@shapingautonomy.com"
                                            target="_blank"
                                            // sx={{ mr: 2, display: { sm: 'none' } }}
                                        >
                                            <EmailIcon fontSize="large" />
                                        </IconButton>

                                </Grid>
                                <FooterContainer maxWidth="lg" component="footer">
                                    <div style={{display:"flex", justifyContent:"center"}}>
                                        <Link style={{textDecoration:"none", marginRight:"10px"}} color="inherit" href="/privacy">
                                            Privacy Policy
                                        </Link>
                                        .
                                        <Link style={{textDecoration:"none", marginLeft:"10px"}} color="inherit" href="/terms-of-use">
                                            {"   "} Terms of Use
                                        </Link>
                                    </div>
                                    <Box mt={5}>
                                        <Copyright />
                                    </Box>

                                </FooterContainer>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </Grid>

            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>LinkedIn Community Space</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Our LinkedIn space is currently under progress. Leave us your LinkedIn public profile URL and we will invite you to the space.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="LinkedIn public profile URL"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={Linkedin}
                        onChange={handleLinkedinChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleLinkedinSubmit}>Confirm</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}