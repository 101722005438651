import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'normalize.css/normalize.css';
import './App.css';
import Home from './components/Main';
import GetInvolved from './components/GetInvolved';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import ReactGA from 'react-ga4';
import GA from './useGaTracker';
import {createTheme, ThemeProvider } from '@mui/material/styles';
import history from './history';
const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    }
});

function initializeReactGA() {
  ReactGA.set({ page: window.location.pathname });
  // ReactGA.pageview(window.location.pathname);
}

function App() {
  GA.init() && initializeReactGA()
  return (
      <ThemeProvider theme={theme}>
      <BrowserRouter history={history}>
        <Routes>
          <Route path="/get-involved" element={<GetInvolved />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
