import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logoIMG from "../assets/logo192.png";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {useMediaQuery} from "./hooks";
import {useNavigate} from "react-router-dom";



interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
    {name:'Get involved', address:"/get-involved"},
    {name:'Mission', address:"#mission"},
    {name:'About', address:"#about"},
    {name:'Partners', address:"#partners"},
    {name:'Contact', address:"/contact"}
];

const theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Poppins',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        }
    }
);


export default function Contact(props: Props) {
    const [value, setValue] = React.useState(0);
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const history = useNavigate();
    const isMobile = useMediaQuery('(max-width: 800px)');
    const isIpad = useMediaQuery('(min-width: 820px) and (max-width: 900px)');
    const isXSmallPhone = useMediaQuery('(max-width: 290px)');
    const isWideHeight = useMediaQuery('(min-height: 870px)');
    const isWideScreen = useMediaQuery('(min-width: 4000px)');
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClickScroll = (address) => {
        if(address.startsWith("#")) {
            history("/")
            setTimeout(() => {
                const aboutPageNode = document.getElementById(address);
                aboutPageNode.scrollIntoView({behavior: "smooth"});
            }, 0);
        } else {
            history(address)
        }

    };
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/*<Typography variant="h6" sx={{ my: 2 }}>*/}
            {/*    SHAPING AUTONOMY*/}
            {/*</Typography>*/}
            <img src={logoIMG} style={{maxWidth:"130px", margin:"15px 0 25px 0"}}/>
            <Divider sx={{ bgcolor: "white" }} />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} component={Button} onClick={()=>{handleClickScroll(item.address)}}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{background: trigger ? "black" : "none", boxShadow: !trigger ? "none" : "blue"}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton href={"/"} edge="start" color="inherit" aria-label="menu" sx={{marginRight: {sm: "auto"}, marginLeft: {xs: "auto" , sm: "0"}, display: 'flex'}}>
                        <img src={logoIMG}  onClick={()=>{}} style={{maxWidth:"100px", marginTop:"7px", flexGrow: 1, display: { xs: 'none', sm: 'block' }}}/>
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item.name} sx={{ color: '#fff' }} onClick={()=>{handleClickScroll(item.address)}}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "black", color: "white" },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xs={12} sx={{height: `${isWideHeight ? "100vh" : "100%"}`, overflow:"hidden", background:"black"}}>
                        <ThemeProvider theme={theme}>
                            <div style={{width:`${isXSmallPhone ? "100%" : "70%"}`, position:"relative", margin:"150px auto", textAlign:"center", height: "100%", zIndex: 5}}>
                                <Typography variant="h3" component="h3" sx={{color:"white", marginBottom:"20px", whiteSpace: {xs: "normal", xl:"nowrap"}}}>
                                    Contact Us
                                </Typography>
                                <Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"30px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                    General Information & Questions
                                </Typography>
                                <Typography variant="p" component="p" sx={{color:"white"}}>
                                    info@shapingautonomy.com
                                </Typography>
                                <Typography variant="h6" noWrap component="h6" sx={{color:"#3b63f8", margin:"30px 0"}}>
                                    Media
                                </Typography>
                                <Typography variant="p" component="p" sx={{color:"white"}}>
                                    media@shapingautonomy.com
                                </Typography>
                                <Typography variant="h6" noWrap component="h6" sx={{color:"#3b63f8", margin:"30px 0"}}>
                                    Partnership inquiries
                                </Typography>
                                <Typography variant="p" component="p" sx={{color:"white"}}>
                                    partnership@shapingautonomy.com
                                </Typography>
                                <Typography variant="h6" noWrap component="h6" sx={{color:"#3b63f8", margin:"30px 0"}}>
                                    Share feedback or ideas
                                </Typography>
                                <Typography variant="p" component="p" sx={{color:"white"}}>
                                    If you have ideas around this project's initiative and its future direction or you would simply like to leave us feedback, click below.
                                </Typography>
                                <Button href="https://forms.gle/Jzo9gSciEz88tt5m6" target="_blank" variant="contained" sx={{margin:"30px auto", display:"block", backgroundColor:"#3b63f8", color:"white", maxWidth:"150px"}}>
                                    Go to Form
                                </Button>
                            </div>

                        </ThemeProvider>
                    </Grid>

                </Grid>

            </ThemeProvider>
        </Box>
    );
}