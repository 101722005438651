import * as React from 'react';
import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from "./hooks";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import logoIMG from "../assets/logo192.png";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";



interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

function Copyright() {
    return (
        <Typography variant="body2" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://shapingautonomy.com">
                Shaping Autonomy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;
const navItems = [
    {name:'Get involved', address:"/get-involved"},
    {name:'Mission', address:"#mission"},
    {name:'About', address:"#about"},
    {name:'Partners', address:"#partners"},
    {name:'Contact', address:"/contact"}
];

const theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Poppins',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        }
    }
);


export default function PrivacyPolicy (props: Props) {
    const [value, setValue] = React.useState(0);
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const history = useNavigate();
    const isMobile = useMediaQuery('(max-width: 800px)');
    const isIpad = useMediaQuery('(min-width: 820px) and (max-width: 900px)');
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClickScroll = (address) => {
        if(address.startsWith("#")) {
            history("/")
            setTimeout(() => {
                const aboutPageNode = document.getElementById(address);
                aboutPageNode.scrollIntoView({behavior: "smooth"});
            }, 0);
        } else {
            history(address)
        }

    };
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/*<Typography variant="h6" sx={{ my: 2 }}>*/}
            {/*    SHAPING AUTONOMY*/}
            {/*</Typography>*/}
            <img src={logoIMG} style={{maxWidth:"130px", margin:"15px 0 25px 0"}}/>
            <Divider sx={{ bgcolor: "white" }} />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} component={Button} onClick={()=>{handleClickScroll(item.address)}}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const container = window !== undefined ? () => window().document.body : undefined;
    const FooterContainer = styled(Container)(({ theme }) => ({
        ...theme.components.MuiContainer,
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        zIndex: 5,
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    }));

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{background: "black", boxShadow: !trigger ? "none" : "blue"}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton href={"/"} edge="start" color="inherit" aria-label="menu" sx={{marginRight: {sm: "auto"}, marginLeft: {xs: "auto" , sm: "0"}, display: 'flex'}}>
                        <img src={logoIMG}  onClick={()=>{}} style={{maxWidth:"100px", marginTop:"7px", flexGrow: 1, display: { xs: 'none', sm: 'block' }}}/>
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item.name} sx={{ color: '#fff' }} onClick={()=>{handleClickScroll(item.address)}}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: "black", color: "white" },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xs={12}>
                        <ThemeProvider theme={theme}>
                            <div style={{width:"80%", position:"relative", margin:"150px auto", overflow:"scroll", zIndex: 5}}>
                                <Typography variant="h3" component="h3" sx={{ marginBottom:"20px", whiteSpace: {xs: "normal", xl:"nowrap"}}}>
                                    Privacy Policy
                                </Typography>
                                <Typography variant="p" component="p">
                                    This privacy notice for Shaping Autonomy ("We", "Us"), describes how and why we might collect, use, and/or share your information when you use our services, such as when you:
                                    <ul>
                                        <li> Visit our website at shapingautonomy.com, or any website of ours that links to this privacy notice </li>
                                        <li> Engage with us in other related ways, including through social media, events, and our partners' services and websites </li>
                                    </ul>
                                </Typography>
                                <Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                    Information we collect and how we use it
                                </Typography>
                                <Typography variant="p" component="p">
                                    Please note that Shaping Autonomy is a research project by nature and does not and will not sell names, and/or personal information to mass marketers or other entities. We do not exchange information for the purpose of targeted advertisement or for generating profit.
                                    <br/>
                                    <br/>
                                    While you are using our website, we may collect your email address for our listserv to keep you updated on Shaping Autonomy's efforts. Additionally we may collect your LinkedIn public profile URL to invite you to Shaping Autonomy's LinkedIn community space.
                                    <br />
                                    <br />
                                    If you are interested to join the internal team, you'll be asked to fill out a form asking you about your field of work and experience and your goals and availability for contributing to Shaping Autonomy efforts. In such forms, we might ask you to provide name, email and/or your LinkedIn public profile URL. We do not collect other types of personal information.
                                    forms are collected through Google Forms and responses are held in encrypted Google Cloud that belongs to Shaping Autonomy.
                                    <br />
                                    <br />
                                    If you are interested to join the industry team supporting and contributing to Shaping Autonomy efforts, we ask you to schedule a talk with us through a Calendly link. The talks are conducted through scheduled encrypted Zoom meetings through voice and/or video based on your preference. These talks are informal interviews where we ask you for your perspectives on the existing challenges based on your experience as well as your thoughts on how to make an impact in industry through Shaping Autonomy.
                                    The interviews are conducted anonymously meaning that your name and participation won't be released and shared in anyway. After your participation, you'll be prioritized in joining the community and collaborative spaces to define projects, panels and working groups relevant to challenges as well as to participate in other projects already defined. Please note that your insights in the interviews are deemed as your own personal perspective and not representative of your current or past companies. The talks are not recorded in anyway and only text notes are taken from your insights by interviewees who are from Shaping Autonomy internal research team.
                                    forms are collected through Google Forms and responses are held in encrypted Google Cloud that belongs to Shaping Autonomy.
                                    <br />
                                    <br />
                                    If you are interested to join the academic team supporting and contributing to Shaping Autonomy efforts, you'll be asked to fill out a form asking you about your field of work and experience and your goals and availability for contributing to projects. we might ask you to provide name, email and/or your LinkedIn public profile URL.
                                    If you are a faculty member, we ask you to provide information about your lab. If you are a student, we ask you about your current field of study and your degree which will help us in forming teams for projects.
                                    We do not collect other types of personal information.
                                    forms are collected through Google Forms and responses are held in encrypted Google Cloud that belongs to Shaping Autonomy.
                                    <br/>
                                    <br/>
                                </Typography>

                                <Typography variant="p" component="p" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                    Information automatically collected
                                </Typography>

                                <Typography component="p" variant="p">
                                   Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Website. We automatically collect certain information when you visit, use, or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, and information about how and when you use our Website, and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes to improve the way our website works. Like many websites, we also collect information through anonymized analytical cookies and similar technologies. The information we collect includes: Log and Usage Data; Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Website and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Website (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
                                    <br/>
                                    <br/>
                                </Typography>


                                <Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                    Keeping your information safe
                                </Typography>
                                <Typography variant="p" component="p">

                                    We have technical processes and procedures in place to protect your personal information.
                                    We restrict access to your information by unauthorized persons. We require our third party service providers to comply with confidentiality requirements and to not use your personal information for marketing purposes without your consent.
                                    However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
                                    Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.

                                    We reserve the right to use personal information to protect the security or integrity of shapingautonomy.com to protect against a threat to personal safety or destruction of property; to protect against legal liability; or to cooperate with government officials or parties in litigation, or as otherwise required by law.
                                <br/>
                                <br/>

                                </Typography>
                                <Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                    Collecting information from minors
                                </Typography>
                                <Typography variant="p" component="p">

                                    We do not knowingly collect data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info@shapingautonomy.com
                                    <br/>
                                    <br/>
                                </Typography>
                                <Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                    Links to other websites
                                </Typography>
                                <Typography variant="p" component="p">
                                    The Website may provide links to other websites. We are not responsible for the privacy or security practices of other websites (including security, collection of personal information, or use of cookies) or practices of other organizations and individuals.
                                    <br />
                                    <br />
                                </Typography>
                                <Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
                                    Do we make updates to this notice? </Typography>
                                <Typography variant="p" component="p">
                                    Yes, we will update this notice as necessary and to stay compliant with relevant laws. We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                                    <br />
                                    <br />
                                </Typography>


<Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
    How can you contact us about this notice?
</Typography>
                                <Typography variant="p" component="p">

                                    If you have questions or comments about this notice, you may email us at info@shapingautonomy.com
                                    <br />
                                    <br />
                                </Typography>
                                <Typography variant="h6" component="h6" sx={{color:"#3b63f8", marginBottom:"15px", whiteSpace: {md: "normal", xl:"nowrap"}}} >
How can you review, update, or delete the data we collect from you?
                                </Typography>
                                <Typography variant="p" component="p">

Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please send us an email at: info@shapingautonomy.com
                                    <br />
                                    <br />
                                </Typography>
                                <Typography variant="p" component="p">

                                    Effective Date: 06/10/2023

                                    <br />
                                    <br />
                                </Typography>


                            </div>

                        </ThemeProvider>
                    </Grid>
                    <FooterContainer maxWidth="lg" component="footer">
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <Link style={{textDecoration:"none", marginRight:"10px"}} color="inherit" href="/privacy">
                                Privacy Policy
                            </Link>
                            .
                            <Link style={{textDecoration:"none", marginLeft:"10px"}} color="inherit" href="/terms-of-use">
                                {"   "} Terms of Use
                            </Link>
                        </div>
                        <Box mt={5}>
                            <Copyright />
                        </Box>

                    </FooterContainer>
                </Grid>

            </ThemeProvider>
        </Box>
    )
}